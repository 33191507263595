<script setup lang="ts">
import { localeLink } from '#imports'

definePageMeta({
  layout: 'site',
  title: 'pages.index.title',
  description: 'pages.index.description',
})

const musicMakersImages = [
  '/images/music-makers-1_750.jpg',
  '/images/music-makers-2_750.jpg',
  '/images/music-makers-3_750.jpg',
  '/images/music-makers-4_750.jpg',
]
const soundDesignersImages = ['/images/sound-designers-1_750.jpg']

const desktopImages = [
  '/images/desktop/dvn-search-2-perspective_800.jpg',
  '/images/desktop/dvn-download-perspective_800.jpg',
  '/images/desktop/dvn-decentsampler-perspective_800.jpg',
  '/images/desktop/dvn-ableton-instrument-rack-perspective_800.jpg',
  '/images/desktop/dvn-mpc-key-program-perspective_800.jpg',
  '/images/desktop/dvn-mpc-beat-program-perspective_800.jpg',
  '/images/desktop/dvn-instrument-form-2-perspective_800.jpg',
  '/images/desktop/dvn-videos-perspective_800.jpg',
]

const userStore = useUserStore()
</script>

<template>
  <main class="dvn-site-content">
    <section class="dvn-site-home-top">
      <div class="dvn-site-home-banner-image"></div>
      <div class="dvn-site-home-banner-text">
        <div class="dvn-site-home-top-text">
          <h1>DiViNe Samples</h1>
          <p class="p1">{{ $t('pages.index.banner.title.1') }}</p>
          <p class="p2">{{ $t('pages.index.banner.title.2') }}</p>
          <p class="d-none d-md-block p3">{{ $t('pages.index.banner.title.3') }}</p>
          <p class="p4">{{ $t('pages.index.banner.title.4') }}</p>
        </div>
        <NuxtLink :to="localeLink('register')" class="mt-1 dvn-site-link-button big">{{
          $t('pages.index.banner.button')
        }}</NuxtLink>
      </div>
    </section>

    <section id="carousel">
      <CatalogInstrumentFeaturedCarousel />
    </section>

    <section id="music-makers" class="dvn-site-page-block rev">
      <CoreImageSlideShow
        :images="musicMakersImages"
        :refresh-time="10"
        width="750"
        class="page-block-img rounded-image"
      />

      <div class="dvn-site-block-info">
        <h2 class="text-gradient">{{ $t('pages.index.section1.title') }}</h2>
        <p class="dpmt">{{ $t('pages.index.section1.p0') }}</p>
        <p class="dpmt">{{ $t('pages.index.section1.p1') }}</p>
        <ul class="dvn-site-list smaller darker">
          <li>{{ $t('pages.index.section1.p1-1') }}</li>
        </ul>
        <p class="dpmt">{{ $t('pages.index.section1.p2') }}</p>
        <ul class="dvn-site-list smaller darker">
          <li>{{ $t('pages.index.section1.p2-1') }}</li>
        </ul>
        <p class="dpmt">{{ $t('pages.index.section1.p3') }}</p>
        <ul class="dvn-site-list smaller darker">
          <li>{{ $t('pages.index.section1.p3-1') }}</li>
          <li>{{ $t('pages.index.section1.p3-2') }}</li>
        </ul>
        <p class="dpmt">{{ $t('pages.index.section1.p4') }}</p>
        <ul class="dvn-site-list smaller darker">
          <li>{{ $t('pages.index.section1.p4-1') }}</li>
          <li>{{ $t('pages.index.section1.p4-2') }}</li>
          <li>{{ $t('pages.index.section1.p4-3') }}</li>
        </ul>
        <NuxtLink
          class="dvn-site-link-button big dpmtl"
          :to="
            userStore.isAuthenticated
              ? localeLink('private-catalog-search')
              : localeLink('site-catalog-search')
          "
          >{{ $t('pages.index.section1.button') }}</NuxtLink
        >
      </div>
    </section>

    <section id="sound-designers" class="dvn-site-page-block gray">
      <div class="dvn-site-block-info">
        <h2 class="text-gradient">{{ $t('pages.index.section2.title') }}</h2>
        <p class="dpmt">{{ $t('pages.index.section2.p1') }}</p>
        <ul class="dvn-site-list smaller darker">
          <li>{{ $t('pages.index.section2.p1-1') }}</li>
          <li>{{ $t('pages.index.section2.p1-2') }}</li>
          <li>{{ $t('pages.index.section2.p1-3') }}</li>
          <li>{{ $t('pages.index.section2.p1-4') }}</li>
        </ul>
        <p class="dpmt">{{ $t('pages.index.section2.p2') }}</p>
        <p class="dpmt">{{ $t('pages.index.section2.p3') }}</p>
        <p class="dpmt">{{ $t('pages.index.section2.p4') }}.</p>

        <div style="display: flex; flex-direction: column; align-items: center">
          <div>
            <hr style="margin-top: 1.5em; margin-bottom: 1.5em" />
            <NuxtLink :to="localeLink('/site/youtubers')" style="font-size: 1.1em">{{
              $t('pages.index.section2.p5')
            }}</NuxtLink>
            <MiscYoutubersJoinUs style="font-size: 1.1em" />
          </div>
        </div>
      </div>
      <CoreImageSlideShow
        :images="soundDesignersImages"
        :refresh-time="10"
        width="750"
        class="page-block-img rounded-image"
      />
    </section>

    <section id="divine-desktop" class="dvn-site-page-block rev">
      <CoreImageSlideShow
        :images="desktopImages"
        :refresh-time="10"
        alt="DiViNe Desktop"
        width="750"
        class="page-block-img rounded-image"
      />
      <div class="dvn-site-block-info">
        <h2 class="text-gradient">{{ $t('pages.index.section3.title') }}</h2>

        <p class="dpmt">{{ $t('pages.site-desktop-download.p1') }}</p>
        <p class="dpmt">{{ $t('pages.site-desktop-download.p2') }} :</p>
        <ul class="dvn-site-list smaller darker">
          <li>{{ $t('pages.site-desktop-download.li1') }}</li>
          <li>{{ $t('pages.site-desktop-download.li2') }}</li>
          <li>{{ $t('pages.site-desktop-download.li3') }}</li>
        </ul>

        <p class="dpmt">{{ $t('pages.site-desktop-download.p3') }} :</p>
        <ul class="dvn-site-list smaller darker">
          <li>{{ $t('pages.site-desktop-download.li4') }}</li>
          <li>{{ $t('pages.site-desktop-download.li5') }}</li>
          <li>{{ $t('pages.site-desktop-download.li6') }}</li>
          <li>{{ $t('pages.site-desktop-download.li7') }}</li>
        </ul>

        <NuxtLink
          class="dvn-site-link-button big dpmtl"
          :to="localeLink('site-desktop-download')"
          >{{ $t('pages.index.section3.button') }}</NuxtLink
        >
      </div>
    </section>

    <!-- <section id="support-divine" class="dvn-site-page-block center">
      <div class="dvn-site-block-info">
        <h2 class="text-gradient">{{ $t('pages.index.section4.title') }}</h2>
        <p>{{ $t('pages.index.section4.p1') }}</p>
        <p class="dpmtl">{{ $t('pages.index.section4.p2') }} :</p>
        <ul class="dvn-site-list dpmt">
          <li>{{ $t('pages.index.section4.li1') }}</li>
          <li>{{ $t('pages.index.section4.li2') }}</li>
          <li>{{ $t('pages.index.section4.li3') }}</li>
        </ul>
        <div class="links-donate dpmtl">
          <NuxtLink to="#support-divine" class="dvn-site-link-button">{{
            $t('pages.index.section4.donate-patreon')
          }}</NuxtLink>
          <NuxtLink to="#support-divine" class="dvn-site-link-button">{{
            $t('pages.index.section4.donate-oc')
          }}</NuxtLink>
        </div>
      </div>
    </section> -->

    <section id="create-account" class="dvn-site-page-block gray center">
      <h2>{{ $t('pages.index.section5.title') }}</h2>
      <NuxtLink :to="localeLink('register')" class="dvn-site-link-button big dpmtl">{{
        $t('pages.index.section5.button')
      }}</NuxtLink>
    </section>
  </main>
</template>

<style lang="css" scoped>
section.dvn-site-home-top {
  position: relative;
  max-height: 320px;
  height: calc(100vw / 2000 * 507);
}

div.dvn-site-home-banner-image {
  width: 100%;
  height: 100%;
  background-image: url('/images/divine-banner_2000.jpg');
  filter: brightness(62%);
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: cover;
}

div.dvn-site-home-banner-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  filter: drop-shadow(0px 0px 20px #022c5b);

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

div.dvn-site-home-top-text {
  /* max-width: 45em; */
  max-width: 70em;
}

section.dvn-site-home-top h1 {
  font-size: 4em;
  text-align: center;
  font-weight: 400;
  color: white;
}

section.dvn-site-home-top p {
  font-size: 2.2em;
  text-align: center;
  font-weight: 600;
  line-height: 1.2em;
  color: white;
}

section.dvn-site-home-top .p1 {
  font-size: 2em;
}

section.dvn-site-home-top .p2 {
  font-size: 1.8em;
  margin-top: 0.2em;
}

section.dvn-site-home-top .p3 {
  font-size: 1.3em;
}

section.dvn-site-home-top .p4 {
  font-size: 1.8em;
  margin-top: 0.2em;
}

section.dvn-site-home-top a {
  margin: 2vw 0 1em 0;
}

div.links-donate > a {
  margin: 1em;
}

section#create-account h2 {
  text-align: center;
}

@media screen and (max-width: 1279px) {
  section.dvn-site-home-top {
    font-size: 1.4vw;
  }

  section.dvn-site-home-top a.dvn-site-link-button.big {
    font-size: calc(8px + 0.5vw);
  }
}
</style>
