<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    images?: string[]
    refreshTime?: number
    fadeFactor?: 1 | 2 | 3
  }>(),
  {
    images: () => [],
    refreshTime: 6, // in seconds
    fadeFactor: 3,
  },
)

const index = ref(0)
const show = ref(true)
const hide = ref(false)

const image = computed(() => {
  return props.images[index.value]
})

const fadeDuration = computed(() => {
  if (props.fadeFactor > 3) return '1s'
  return `${props.fadeFactor / 3}s`
})

function fadeInOut() {
  if (props.images.length <= 1) return
  // fade in
  setTimeout(
    () => {
      show.value = true
      hide.value = false
    },
    props.refreshTime * 1000 + 1000,
  )

  // fade out
  setTimeout(
    () => {
      show.value = false
      hide.value = true
    },
    props.refreshTime * 1000 - 1000,
  )
}

let setTimeoutHandler: NodeJS.Timeout

function changeImage() {
  if (props.images.length <= 1) return
  index.value = index.value + 1
  if (index.value >= props.images.length) index.value = 0
  fadeInOut()

  setTimeoutHandler = setTimeout(changeImage, props.refreshTime * 1000)
}

onMounted(() => {
  fadeInOut() // first cycle
  setTimeoutHandler = setTimeout(changeImage, props.refreshTime * 1000)
})

onUnmounted(() => {
  clearTimeout(setTimeoutHandler)
})
</script>

<template>
  <img v-if="props.images.length" :src="image" class="transition" :class="{ show, hide }" />
</template>
<style lang="css" scoped>
.transition {
  --time: v-bind(fadeDuration);
  --timing: ease-out; /* ease | ease-in | ease-out |ease-in-out | linear */
  transition: all var(--time) var(--timing);
  -webkit-transition: all var(--time) var(--timing);
  -moz-transition: all var(--time) var(--timing);
}
.show {
  opacity: 1;
}
.hide {
  opacity: 0;
}
</style>
