<script setup lang="ts">
import { useDisplay } from 'vuetify'

const carouselHeight = 480 // carousel height
const carouselWidth = '1200px' // carousel width
const carouselHeight_sm = 500 // carousel height for small display
const carouselWidth_sm = '380px' // carousel width for small display
const maxItems_sm = 8
const timeInterval = 12000
const itemWidth = 1000 //1000
const itemWidth_sm = 380 //370

const { smAndDown } = useDisplay()
const { $trpc } = useNuxtApp()
const { t } = useI18n()
const { data } = await $trpc.cat_Instruments.getHomeCarouselItems.useQuery()

const items = computed(() => {
  if (!data.value) return []
  if (smAndDown.value) {
    return data.value.slice(0, maxItems_sm)
  }
  return data.value
})

// players
const videoPlayer = useVideoPlayer()
const audioPlayer = useAudioPlayer()
audioPlayer.isAudioPlayerVisible = false
</script>

<template>
  <div class="container">
    <div class="d-flex justify-center pa-0 pb-8">
      <span class="text-gradient featured-title" :class="{ sm: smAndDown }">{{
        t('pages.index.carousel.featured')
      }}</span>
    </div>

    <VCarousel
      v-if="items && items.length"
      :height="smAndDown ? carouselHeight_sm : carouselHeight"
      :interval="timeInterval"
      cycle
      theme="light"
      class="carousel"
      :class="{ sm: smAndDown }"
    >
      <div class="center-item">
        <CatalogInstrumentFeaturedCarouselItem
          v-for="item in items"
          :key="item._id.toString()"
          :instrument="item"
          :height="smAndDown ? carouselHeight_sm : carouselHeight"
          :width="smAndDown ? itemWidth_sm : itemWidth"
        ></CatalogInstrumentFeaturedCarouselItem>
      </div>
    </VCarousel>

    <!-- overlays -->
    <CatalogMediaAudioPlayer v-model="audioPlayer.isAudioPlayerVisible" />
    <CatalogMediaVideoPlayer
      v-model="videoPlayer.selectedVideo"
      :title="videoPlayer.selectedVideo?.title"
      autoplay
    />
  </div>
</template>
<style scoped>
.container {
  padding: 3em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel {
  width: v-bind(carouselWidth);
}

.carousel.sm {
  width: v-bind(carouselWidth_sm);
}

.featured-content {
  padding: 0;
}
.featured-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.6em;
  font-weight: 600;
}
.featured-title.sm {
  font-size: 2em;
}
.center-item {
  margin: 0 auto;
}
</style>
